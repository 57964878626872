import api from "api"
import { AcceptedJobOverview } from "api/resources/jobs/types"
import ErrorPage from "components/ErrorPage"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { GlobalContext } from "GlobalState"
import { useContext, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { routes } from "utils/routes"

const CheckAccess = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [skipJobLockedTill, setSkipJobLockedTill] = useState(false)

  const navigate = useNavigate()

  const { overview } = useContext(GlobalContext)

  useEffect(() => {
    const getOverview = async () => {
      setLoading(true)
      const res = await api.jobs.overview()
      if (res.isSuccessful) {
        // @ts-expect-error type narrowing needed
        if (res.data.jobsLockedTill !== undefined) {
          overview.set(res.data as AcceptedJobOverview)
          navigate(routes.acceptedOffer)
          setSkipJobLockedTill(false)
        } else {
          setSkipJobLockedTill(true)
        }
      } else {
        // TODO: Need to create an unauthorized access page
        setError("You are not authorized to access this platform")
      }
      setLoading(false)
    }
    getOverview()
    /**
     * if we put overview in deps,
     * it would set-off an infinite loop because
     * we are setting overview in getOverview()
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <LoadingOverlay showLogo />
      ) : (
        error && <ErrorPage message="You don't have access to this page" />
      )}
      {!loading &&
        !error &&
        (skipJobLockedTill ||
          (overview.jobsLockedTill &&
            new Date(overview.jobsLockedTill) < new Date())) && <Outlet />}
    </>
  )
}

export default CheckAccess

import { NotificationsPopup } from "@suraasa/notifications"
import {
  Avatar,
  Container,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { MenuDivider } from "@szhsin/react-menu"
import axios from "api/axios"
import BookClosed from "assets/navigation/BookClosed"
import BookOpen from "assets/navigation/BookOpen.svg"
import HomeLine from "assets/navigation/HomeLine"
import HomeSmile from "assets/navigation/HomeSmile.svg"
import JobSearch from "assets/navigation/JobsSearch.svg"
import User from "assets/navigation/User"
import UserCircle from "assets/navigation/UserCircle.svg"
import clsx from "clsx"
import ProfileNotCompletedBanner from "components/auth/ProfileNotCompletedBanner"
import { GlobalContext } from "GlobalState"
import {
  Journal,
  MediaVideoList,
  MicrophoneSpeaking,
  Shop,
  Suitcase,
  ViewGrid,
} from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
import {
  buildUserName,
  getPlatformURL,
  notificationHelper,
} from "utils/helpers"
import { routes } from "utils/routes"

import BackButton, { BackButtonProps } from "./BackButton"
import Link from "./Link"
import Sidebar from "./Sidebar"

const NAVBAR_HEIGHT = 62
const useStyles = createUseStyles(theme => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.navbar,
  },

  nav: {
    height: NAVBAR_HEIGHT,
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    backgroundColor: "white",
    position: "relative",
    zIndex: theme.zIndex.navbar,
    transition: "box-shadow 225ms ease-in-out",
  },

  gutterBottom: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(8),
    },
  },
  containerRoot: {
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  enableShadow: {
    boxShadow: "1px 1px 5px 0px #0000001A",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  divider: {
    height: "100%",
    width: 2,
    background: theme.colors.onSurface[200],
  },
  textFontFamily: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
    color: theme.colors.onSurface[500],
  },
  notificationContainer: {
    padding: "6px 12px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  notificationPopup: {
    flexGrow: 1,
  },
}))

export type NavbarProps = {
  slotEnd?: React.ReactElement
  className?: string
  gutterBottom?: boolean
  hideBackButton?: boolean
  backButtonProps?: BackButtonProps
  showAuth?: boolean
  hideBanner?: boolean
}

const moreLinks = [
  {
    name: "Store",
    link: getPlatformURL("suraasa", "/store"),
    icon: Shop,
  },
  {
    name: "Masterclasses",
    link: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
  },
  {
    name: "Blogs",
    link: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
  },
  {
    name: "Podcasts",
    link: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
  },
]
const Divider = () => {
  const classes = useStyles()
  return <div className={classes.divider} />
}

const Navbar = ({
  gutterBottom = true,
  hideBackButton = false,
  hideBanner = false,
  backButtonProps,
}: NavbarProps) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const authInfo = getAuthInfo()
  const userName = authInfo ? buildUserName(authInfo.user) : ""

  const { notifications } = useContext(GlobalContext)
  const navigate = useNavigate()

  const [showSidebar, setShowSidebar] = useState(false)

  const handleLogout = () => {
    clearAuthInfo()
    const url = new URL(`${import.meta.env.VITE_SSO_URL}/logout`)
    url.searchParams.append("origin", `${window.location.origin}`)
    url.searchParams.append("platform", USER_TYPE)
    window.location.href = url.href
  }

  if (!mdUp && authInfo) {
    return (
      <>
        <nav
          className="flex items-center justify-between px-2 py-1"
          id="topNavbar"
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: theme.zIndex.navbar,
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
            transition: "top 0.3s",
          }}
        >
          <div className="shrink-0">
            <button onClick={() => setShowSidebar(true)}>
              <Avatar name={userName} size="sm" src={authInfo.user?.photo} />
            </button>
            <Sidebar
              handleClose={() => {
                setShowSidebar(false)
              }}
              handleLogout={handleLogout}
              open={showSidebar}
            />
          </div>
          <NotificationsPopup
            axiosInstance={axios}
            className={classes.notificationPopup}
            notifications={notifications}
            onClick={notificationItem =>
              notificationHelper(navigate, notificationItem.action)
            }
          />
        </nav>
        {!hideBanner && <ProfileNotCompletedBanner />}
        {!hideBackButton && (
          <div className="my-2">
            <Container className={classes.containerRoot}>
              <BackButton {...backButtonProps} />
            </Container>
          </div>
        )}
        <div className={clsx({ [classes.gutterBottom]: gutterBottom })} />
      </>
    )
  }

  return (
    <>
      <nav
        id="topNavbar"
        style={{
          boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
          position: "sticky",
          top: 0,
          background: "white",
          borderBottom: `1px solid ${theme.colors.surface[200]}`,
          zIndex: theme.zIndex.navbar,
          transition: "top 0.3s",
        }}
      >
        <Container>
          <div className="flex items-center justify-between">
            {mdUp ? (
              <>
                <ReactRouterLink to={routes.home}>
                  <img
                    alt="suraasa logo"
                    src="https://www.suraasa.com/assets/logos/suraasa-logo-new.svg"
                  />
                </ReactRouterLink>
                <div className="flex gap-1">
                  <Link
                    activeIcon={<img src={HomeSmile} alt="" />}
                    href={getPlatformURL("learn", "/home")}
                    icon={<HomeLine />}
                    text="Home"
                  />
                  <Link
                    activeIcon={<img src={BookOpen} alt="" />}
                    href={getPlatformURL("learn", "/learning")}
                    icon={<BookClosed />}
                    text="Learning"
                  />
                  <Link
                    activeIcon={<img src={JobSearch} alt="" />}
                    href={routes.home}
                    icon={<Suitcase />}
                    text="Jobs"
                  />
                  <div className={classes.notificationContainer}>
                    <NotificationsPopup
                      axiosInstance={axios}
                      className={classes.notificationPopup}
                      notifications={notifications}
                      onClick={notificationItem =>
                        notificationHelper(navigate, notificationItem.action)
                      }
                    />
                    <p className={clsx(classes.textFontFamily)}>
                      Notifications
                    </p>
                  </div>
                  <Link
                    activeIcon={<img src={UserCircle} alt="" />}
                    icon={<User />}
                    menuChildren={
                      <>
                        {authInfo && (
                          <MenuItem
                            className="flex"
                            href={getPlatformURL("learn", "/profile")}
                          >
                            <div className="ml-1">
                              <Typography
                                className="whitespace-pre-wrap"
                                style={{
                                  wordBreak: "break-word",
                                }}
                                variant="strong"
                              >
                                {userName}
                              </Typography>
                              <Typography
                                color="onSurface.500"
                                variant="smallBody"
                              >
                                View Profile
                              </Typography>
                            </div>
                          </MenuItem>
                        )}
                        {authInfo && <MenuDivider />}
                        <MenuItem
                          href={getPlatformURL("learn", "/certificates")}
                        >
                          My Certificates
                        </MenuItem>
                        <MenuItem
                          href={getPlatformURL("learn", "/my-schedule")}
                        >
                          My Schedule
                        </MenuItem>
                        <MenuItem href={getPlatformURL("learn", "/orders")}>
                          Orders and Payments
                        </MenuItem>
                        <MenuItem href={getPlatformURL("learn", "/settings")}>
                          Settings
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            window.open(
                              getPlatformURL("sso", "/help"),
                              "_blank"
                            )
                          }}
                        >
                          Help Center
                        </MenuItem>
                        <MenuItem color="critical" onClick={handleLogout}>
                          Logout
                        </MenuItem>
                      </>
                    }
                    text="Me"
                    isMenu
                  />
                  <div>
                    <Divider />
                  </div>
                  <Link
                    activeIcon={<ViewGrid />}
                    icon={<ViewGrid />}
                    menuChildren={
                      <>
                        {moreLinks.map((x, i) => (
                          <MenuItem
                            href={x.link}
                            key={i}
                            startAdornment={<x.icon />}
                          >
                            <Typography>{x.name}</Typography>
                          </MenuItem>
                        ))}
                      </>
                    }
                    text="More"
                    isMenu
                  />
                </div>
              </>
            ) : (
              <Link
                activeIcon={<img src={HomeSmile} alt="" />}
                href={routes.home}
                icon={<HomeLine />}
                text="Home"
              />
            )}
          </div>
        </Container>
      </nav>
      {!hideBanner && <ProfileNotCompletedBanner />}
      {!hideBackButton && (
        <div className="my-2">
          <Container className={classes.containerRoot}>
            <BackButton {...backButtonProps} />
          </Container>
        </div>
      )}
      <div className={clsx({ [classes.gutterBottom]: gutterBottom })} />
    </>
  )
}

export default Navbar

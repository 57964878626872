import { Button, Container } from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import ShareJobButton from "components/jobs/ShareJobButton"
import ProfileCompletion from "components/ProfileCompletion"
import JobOverviewCard from "components/schoolProfile/JobOverviewCard"
import SchoolProfile from "components/schoolProfile/SchoolProfile"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import { GlobalContext } from "GlobalState"
import { ShareAndroid } from "iconoir-react"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { isProfileComplete } from "utils/helpers"
import toast from "utils/toast"

const SchoolProfilePage = () => {
  const { slug } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const jobSlug = searchParams.get("jobSlug")

  const applyingOnJobId = searchParams.get("applyingOnJobId")

  const [applyingforJob, setApplyingforJob] = useState<boolean | null>(null)

  const { stepsOverview } = useContext(GlobalContext)

  const [jobData, setJobData] = useState<Job>()
  const [profileCompletionDialogOpen, setProfileCompletionDialog] =
    useState(false)

  const getJobOverview = useCallback(async () => {
    const res = await api.jobs.retrieve({
      urlParams: { jobId: jobSlug },
    })
    if (res.isSuccessful) {
      setJobData(res.data)
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
  }, [jobSlug])

  useEffect(() => {
    if (jobSlug) getJobOverview()
  }, [jobSlug, getJobOverview])

  const applyForJob = async () => {
    setApplyingforJob(true)

    const res = await api.jobs.jobApplicant.create({
      urlParams: { jobId: applyingOnJobId },
    })

    if (res.isSuccessful) {
      toast.success("Applied for Job Successfully")
      searchParams.delete("applyingOnJobId")
      setSearchParams(searchParams, { replace: true })

      getJobOverview()
    } else toast.error(res.errors.message || "Failed to apply for job")

    setApplyingforJob(false)
  }

  useEffect(() => {
    if (stepsOverview.loading === true) {
      setApplyingforJob(false)
      return
    }

    if (applyingOnJobId) {
      if (isProfileComplete(stepsOverview)) {
        applyForJob()
        return
      }
      setProfileCompletionDialog(true)
    }

    setApplyingforJob(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsOverview, applyingOnJobId])

  if (applyingforJob === null || applyingforJob === true) return null

  return (
    <>
      {stepsOverview && (
        <ProfileCompletion
          jobId={applyingOnJobId ? Number(applyingOnJobId) : undefined}
          open={profileCompletionDialogOpen}
          toggle={() => setProfileCompletionDialog(false)}
        />
      )}
      <Navbar gutterBottom={false} hideBackButton />

      <Container>
        <div className="my-3.5 flex items-center justify-between">
          <BackButton />
          {jobData && (
            <ShareJobButton
              activator={handleOpen => (
                <Button
                  startAdornment={<ShareAndroid />}
                  variant="text"
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOpen()
                  }}
                >
                  Share
                </Button>
              )}
              jobSlug={jobData.slug}
              position={jobData.position}
              schoolProfile={jobData.school}
              subject={jobData.subject.name}
            />
          )}
        </div>
        {jobData && <JobOverviewCard className="mb-5" data={jobData} />}
      </Container>
      <SchoolProfile slug={slug || ""} />
    </>
  )
}
export default SchoolProfilePage

import {
  Button,
  Divider,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Country, State } from "api/resources/global/types"
import { Gender } from "api/resources/profile/types"
import { STEPS } from "components/ProfileCompletion/constants"
import Heading from "components/ProfileCompletion/Heading"
import { ProfileCompletionContext } from "components/ProfileCompletion/utils"
import { ArrowRight, QuestionMark } from "iconoir-react"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTheme } from "react-jss"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import isMobilePhone from "validator/es/lib/isMobilePhone"

const genderList: {
  label: string
  value: Gender
}[] = [
  { label: "Male", value: Gender.MALE },
  { label: "Female", value: Gender.FEMALE },
  { label: "Prefer not to say", value: Gender.PREFER_NOT_TO_SAY },
]

type Form = {
  countryCode: string
  phoneNumber: number
  gender: Gender | null
  nationalityId: string | null
  countryId: string | null
  stateId: string | null
}

const PersonalDetails = () => {
  const { setStep, countries } = useContext(ProfileCompletionContext)
  const theme = useTheme<Theme>()
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Form>()

  /**
   * undefined --> options are being fetched(loading...)
   * null --> default || api call failed || options don't exist
   */
  const [states, setStates] = useState<State[] | null | undefined>(null)
  const country = watch("countryId")

  const fetchStates = useCallback(
    async (countryId: Country["uuid"]) => {
      setStates(undefined)
      setValue("stateId", null)
      const res = await api.global.states.list({
        urlParams: {
          country: countryId,
        },
        params: {
          page: -1,
        },
      })
      if (res.isSuccessful) {
        setStates(res.data)
      } else {
        setStates(null)
      }
    },
    [setValue]
  )

  const onSubmit = handleSubmit(async formData => {
    const res = await api.profile.update({
      data: {
        ...formData,
        phoneNumber: `${formData.countryCode}${formData.phoneNumber}`,
      },
    })

    if (res.isSuccessful) {
      setStep(STEPS.MarketingData)
    } else {
      handleErrors(setError, res.errors)
    }
  })

  useEffect(() => {
    if (!country) {
      setStates(null)
    }
  }, [country])

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.profile.retrieve()
      if (res.isSuccessful) {
        if (res.data.countryId) {
          fetchStates(res.data.countryId)
        }
        reset({
          gender: res.data.gender,
          nationalityId: res.data.nationality?.uuid,
          countryId: res.data.countryId,
          stateId: res.data.stateId,
          phoneNumber: res.data.phoneNumber?.number,
          countryCode: res.data.phoneNumber
            ? `+${res.data.phoneNumber.code}`
            : undefined,
        })
      }
    }
    fetchData()
  }, [fetchStates, reset])

  return (
    <div className="flex flex-col">
      <Heading
        subtitle="You need to answer few questions only the first time you apply for a job through Suraasa"
        title="Personal Details"
      />
      <form className="flex flex-col gap-3" onSubmit={onSubmit}>
        <div className="grid grid-cols-5 flex-col gap-1.75 sm:gap-3 md:flex-row lg:grid-cols-6">
          <Controller
            control={control}
            name="countryCode"
            render={({ field: { onChange, value } }) => (
              <Select
                className="col-span-2 md:col-span-1"
                error={Boolean(errors.countryCode)}
                getOptionLabel={({ name, dialCode }) => `${dialCode} (${name})`}
                getOptionValue={({ dialCode }) => dialCode}
                helperText={errors.countryCode?.message}
                label="Code"
                options={countryCodes}
                value={
                  value
                    ? countryCodes.find(item => item.dialCode === value)
                    : null
                }
                fullWidth
                onChange={val => {
                  onChange(val?.dialCode)
                }}
                required
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <div className="col-span-3 flex items-center gap-1.5">
            <TextField
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
              label="Phone Number"
              placeholder="Ex: 9845XX XXXXX"
              type="tel"
              fullWidth
              {...register("phoneNumber", {
                required: { value: true, message: "Required" },
                validate: value =>
                  isMobilePhone(value.toString(), "any") ||
                  "Please enter a valid phone number",
              })}
            />
            <div className="hidden sm:block">
              <Tooltip
                closeDelay={350}
                title="School may need it to contact you"
              >
                <QuestionMark
                  className="-mb-2"
                  color={theme.colors.onSurface[400]}
                />
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Gender */}
        <div className="flex grid-cols-3 items-start gap-1 md:grid">
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <Select
                className="col-span-1"
                error={Boolean(errors.gender)}
                getOptionLabel={item => item.label}
                getOptionValue={item => item.value.toString()}
                helperText={errors.gender?.message}
                inputLabelProps={{ required: true }}
                isSearchable={false}
                label="Gender"
                options={genderList}
                value={
                  value ? genderList.find(item => item.value === value) : null
                }
                fullWidth
                onChange={val => {
                  onChange(val?.value)
                }}
                required
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <Tooltip
            maxWidth="250px"
            title="Schools may want to hire certain genders to promote workplace diversity"
          >
            <QuestionMark
              className="mt-4"
              color={theme.colors.onSurface[400]}
            />
          </Tooltip>
        </div>
        <div className="grid-cols-3 md:grid">
          <Controller
            control={control}
            name="nationalityId"
            render={({ field: { onChange, value } }) => (
              <Select
                className="col-span-1"
                error={Boolean(errors.nationalityId)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.nationalityId?.message}
                inputLabelProps={{ required: true }}
                label="Nationality"
                options={countries}
                placeholder="Select Country"
                value={
                  value ? countries.find(item => item.uuid === value) : null
                }
                fullWidth
                onChange={val => {
                  onChange(val?.uuid)
                }}
                required
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>

        <div className="flex flex-col">
          <div className="mb-2 flex flex-row items-center">
            <Typography color="onSurface.500" variant="smallBody">
              Current Location
            </Typography>
            <div className="grow pl-2">
              <Divider weight="light" />
            </div>
          </div>
          <div className="grid-cols-6 md:grid">
            <div className="flex flex-col gap-3 md:col-span-5 md:flex-row">
              <Controller
                control={control}
                name="countryId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    error={Boolean(errors.countryId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    helperText={errors.countryId?.message}
                    label="country"
                    options={countries}
                    value={value ? countries.find(c => c.uuid === value) : null}
                    fullWidth
                    isClearable
                    onChange={val => {
                      if (val) {
                        fetchStates(val.uuid)
                      }
                      onChange(val ? val.uuid : "")
                    }}
                    required
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />

              <Controller
                control={control}
                name="stateId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    error={Boolean(errors.stateId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    helperText={errors.stateId?.message}
                    isLoading={states === undefined}
                    label="State/Region/Province"
                    options={states ?? []}
                    value={
                      value
                        ? states && states.find(c => c.uuid === value)
                        : null
                    }
                    fullWidth
                    isClearable
                    required
                    onChange={val => onChange(val ? val.uuid : "")}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </div>
          </div>
        </div>
        <Button
          className="my-3 self-center md:self-start"
          endAdornment={<ArrowRight />}
          loading={isSubmitting}
          type="submit"
        >
          Continue
        </Button>
      </form>
    </div>
  )
}

export default PersonalDetails
